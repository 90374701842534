/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    code: "code",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "To help you identify and fix any errors that you may encounter, our errors follow ", React.createElement(_components.a, {
    href: "https://tools.ietf.org/html/rfc7807",
    title: "RFC standards"
  }, "RFC-7807"), " standards."), "\n", React.createElement(_components.p, null, "Our errors include the following fields:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "type"), " - Link to our developer portal for more information about the error."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "title"), " - Short description about the error."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "status"), " - HTTP status code."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "detail"), " - Longer description about the error."), "\n"), "\n", React.createElement(_components.p, null, "Depending on the type of error we may return additional fields."), "\n", React.createElement(_components.h2, null, "Errors"), "\n", React.createElement(_components.h3, null, "API error"), "\n", React.createElement(_components.p, null, "We can’t process your request. We also return an ", React.createElement(_components.code, null, "errors"), " array that contains a ", React.createElement(_components.code, null, "message"), " field for each error. The ", React.createElement(_components.code, null, "message"), " field provides more detail about the error."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#api-error\",\r\n  \"title\": \"Api error\",\r\n  \"status\": 500,\r\n  \"detail\": \"We are unable to process your request at this time\"\r\n  \"errors\": [\r\n    {\r\n      \"message\": \"Service offline\"\r\n    }\r\n  ]\r\n}\n")), "\n", React.createElement(_components.h3, null, "Bad request"), "\n", React.createElement(_components.p, null, "Your request is missing a parameter, or a value is in the wrong format. To help you identify the parameter, we also return an ", React.createElement(_components.code, null, "errors"), " array that contains the following fields for each incorrect parameter:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "parameter"), " - Name of the parameter that contains the error."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "detail"), " - Short description of the error."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "message"), " - Longer description of the error."), "\n"), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#bad-request\",\r\n  \"title\": \"Bad request\",\r\n  \"status\": 400,\r\n  \"detail\": \"One or more validation errors occurred, see error section for more info\",\r\n  \"errors\": [\r\n    {\r\n      \"parameter\": \"start_time\",\r\n      \"detail\": \"invalid date\",\r\n      \"message\": \"Expected time, got \\\\\\\"\\\\\\\" for start_time\"\r\n    }\r\n  ]\r\n}\n")), "\n", React.createElement(_components.h3, null, "Cannot be modified"), "\n", React.createElement(_components.p, null, "You attempted to change a resource that can't be changed."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#cannot-be-modified\",\r\n  \"title\": \"Cannot be modified\",\r\n  \"status\": 409,\r\n  \"detail\": \"You cannot modify this resource in its current state\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Capabilities missing"), "\n", React.createElement(_components.p, null, "The terminal can't run the action. We return an ", React.createElement(_components.code, null, "errors"), " array that contains a ", React.createElement(_components.code, null, "message"), " field for each error. The ", React.createElement(_components.code, null, "message"), " field provides more information about the error."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#capabilities-missing\",\r\n  \"title\": \"Capabilities missing\",\r\n  \"status\": 400,\r\n  \"detail\": \"The terminal does not support the capability you are trying to use.\", \r\n  \"errors\": [\r\n    {\r\n      \"parameter\": \"processingTerminalId\",\r\n      \"message\": \"Processing terminal does not support pre-authorizations\"\r\n    }\r\n  ]\r\n}\n")), "\n", React.createElement(_components.h3, null, "Card type is not supported"), "\n", React.createElement(_components.p, null, "You can't run transactions with the card type that you provided in the request."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#card-type-not-supported\",\r\n  \"title\": \"Card type is not supported\",\r\n  \"status\": 400,\r\n  \"detail\": \"You cannot process transactions with 'card_type' cards through this account\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Contract already signed"), "\n", React.createElement(_components.p, null, "We couldn’t resend the email because the merchant already signed the contract."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#contract-already-signed\",\r\n  \"title\": \"Contract already signed\",\r\n  \"status\": 400,\r\n  \"detail\": \"The merchant has already signed the contract\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Currency is not supported"), "\n", React.createElement(_components.p, null, "You can't process transactions with the currency that you provided in the request."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#currency-not-supported\",\r\n  \"title\": \"Currency is not supported\",\r\n  \"status\": 400,\r\n  \"detail\": \"You cannot process 'currency_code' transactions through this account\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Forbidden"), "\n", React.createElement(_components.p, null, "You don’t have permission to perform the action that you requested."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#forbidden\",\r\n  \"title\": \"Forbidden\",\r\n  \"status\": 403,\r\n  \"detail\": \"You do not have the required permissions to perform this action\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Funding accounts limit reached"), "\n", React.createElement(_components.p, null, "Funding accounts restricted. You can not have any more than two funding accounts attached to this entity."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#funding-accounts-limit-reached\",\r\n  \"title\": \"Funding accounts limit reached\",\r\n  \"status\": 400,\r\n  \"detail\": \"You can not have any more than two funding accounts attached to this entity\",\r\n}\n")), "\n", React.createElement(_components.h3, null, "Funding accounts restricted"), "\n", React.createElement(_components.p, null, "You created funding instructions for a funding account that can't receive funds."), "\n", React.createElement(_components.p, null, "If the status of the funding account is ", React.createElement(_components.code, null, "pending"), " or ", React.createElement(_components.code, null, "rejected"), ", the funding account can't receive funds. To check the status of a funding account, ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/resources#getFundingAccount",
    title: "Retrieve funding account"
  }, "send a GET request to the Funding Accounts endpoint"), "."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#funding-accounts-restricted\",\r\n  \"title\": \"Funding accounts restricted\",\r\n  \"status\": 400,\r\n  \"detail\": \"One or more funding accounts are restricted and can't receive funding instructions\",\r\n  \"errors\": [\r\n    {\r\n      \"fundingAccountId\": 123,\r\n      \"message\": \"Funding account on hold\"\r\n    }\r\n  ]\r\n}\n")), "\n", React.createElement(_components.h3, null, "Idempotency key in use"), "\n", React.createElement(_components.p, null, "You supplied an ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/idempotency",
    title: "Idempotency"
  }, "idempotency key"), " that you used in a previous request. Idempotency keys must be unique."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#idempotency-key-in-use\",\r\n  \"title\": \"Idempotency-Key in use\",\r\n  \"status\": 409,\r\n  \"detail\": \"'Idempotency-Key' is already in use against a different request\",\r\n}\n")), "\n", React.createElement(_components.h3, null, "Idempotency key missing"), "\n", React.createElement(_components.p, null, "Your request didn't contain an ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/idempotency",
    title: "Idempotency"
  }, "idempotency key"), ". You must supply a unique idempotency key for POST requests and PATCH requests."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#idempotency-key-missing\",\r\n  \"title\": \"Idempotent-key header missing\",\r\n  \"status\": 400,\r\n  \"detail\": \"An 'Idempotency-Key' must be supplied for this request\",\r\n}\n")), "\n", React.createElement(_components.h3, null, "Insufficient funds"), "\n", React.createElement(_components.p, null, "You don't have enough funds to send to your merchant. ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/resources#getFundingBalance",
    title: "Get funding balance"
  }, "View the balance of your funding account"), " to check what funds are available to send to your merchant."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#insufficient-funds\",\r\n  \"title\": \"Insufficient funds\",\r\n  \"status\": 400,\r\n  \"detail\": \"You do not have the required funds to complete this transaction\",\r\n}\n")), "\n", React.createElement(_components.h3, null, "KYC check failed"), "\n", React.createElement(_components.p, null, "We didn't create your entity because it failed our Know Your Customer (KYC) check."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#kyc-check-failed\",\r\n  \"title\": \"Failed KYC Checks\",\r\n  \"status\": 400,\r\n  \"detail\": \"Entity has been rejected due to failing KYC checks\",\r\n}\n")), "\n", React.createElement(_components.h3, null, "National ID in use"), "\n", React.createElement(_components.p, null, "One or more supplied national IDs are not unique. All national IDs must be unique."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#national-id-in-use\",\r\n  \"title\": \"National ID in use\",\r\n  \"status\": 409,\r\n  \"detail\": \"One or more supplied national IDs are not unique. All national IDs must be unique\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "No control prong or authorized signatory"), "\n", React.createElement(_components.p, null, "Your request didn’t indicate which owner is the control prong or the authorized signatory. Set one owner as the control prong or the authorized signatory."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#no-control-prong-or-authorized-signatory\",\r\n  \"title\": \"No control prong or authorized signatory\",\r\n  \"status\": 400,\r\n  \"detail\": \"Set one owner as the control prong or the authorized signatory\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "No pricing agreement exists for the processing account"), "\n", React.createElement(_components.p, null, "We couldn’t resend the email because there is no pricing agreement for the processing account."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#no-pricing-agreement-exists-for-the-processing-account\",\r\n  \"title\": \"No pricing agreement exists\",\r\n  \"status\": 400,\r\n  \"detail\": \"There is no pricing agreement linked to the processing account\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Not acceptable"), "\n", React.createElement(_components.p, null, "We can’t send a response in the MIME-type format that you requested. We return responses only in application/json."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#not-acceptable\",\r\n  \"title\": \"Not acceptable\",\r\n  \"status\": 406,\r\n  \"detail\": \"Resource does not support the representation requested\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Not authorized"), "\n", React.createElement(_components.p, null, "We can’t verify your identity. This can occur if your ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/authentication",
    title: "Authentication"
  }, "Bearer token"), " has expired."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#not-authorized\",\r\n  \"title\": \"Not authorized\",\r\n  \"status\": 401,\r\n  \"detail\": \"Your identity could not be verified\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Not found"), "\n", React.createElement(_components.p, null, "We can’t find the resource that you requested. We also return a ", React.createElement(_components.code, null, "resource"), " field that contains the name of the resource. Make sure that you have included the correct URI."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#not-found\",\r\n  \"title\": \"Not found\",\r\n  \"status\": 404,\r\n  \"detail\": \"Resource not found\",\r\n  \"resource\": \"account\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Not requested by email"), "\n", React.createElement(_components.p, null, "We couldn't resend the email to the merchant because you didn't originally choose to send the information by email."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#not-requested-by-email\",\r\n  \"title\": \"Not requested by email\",\r\n  \"status\": 400,\r\n  \"detail\": \"You can use the Reminders endpoint only for information that we sent by email\",\r\n}\n")), "\n", React.createElement(_components.h3, null, "Payload too large"), "\n", React.createElement(_components.p, null, "Your payload is too large. If you’re uploading a document, make sure that each attachment is less than 6MB."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#payload-too-large\",\r\n  \"title\": \"Payload too large\",\r\n  \"status\": 413,\r\n  \"detail\": \"The payload submitted is too large\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Processing terminal was not accepted"), "\n", React.createElement(_components.p, null, "The terminal can't run your request. We return an ", React.createElement(_components.code, null, "errors"), " array that contains a ", React.createElement(_components.code, null, "message"), " field for each error. The ", React.createElement(_components.code, null, "message"), " field provides more information about the error."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#processing-terminal-not-accepted\",\r\n  \"title\": \"Processing terminal was not accepted\",\r\n  \"status\": 400,\r\n  \"detail\": \"The processing terminal is unable to handle the request, see error section for more info\",\r\n  \"errors\": [\r\n    {\r\n      \"parameter\": \"processingTerminalId\",\r\n      \"message\": \"Processing terminal has been deactivated\"\r\n    }\r\n  ]\r\n}\n")), "\n", React.createElement(_components.h3, null, "Resource already exists"), "\n", React.createElement(_components.p, null, "You attempted to create a resource that already exists."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#resource-already-exists\",\r\n  \"title\": \"Resource already exists\",\r\n  \"status\": 409,\r\n  \"detail\": \"The resource you attempted to create already exists\",\r\n}\n")), "\n", React.createElement(_components.h3, null, "Search too broad"), "\n", React.createElement(_components.p, null, "Your request has produced a list of results that is too large. Narrow your search criteria."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#search-too-broad\",\r\n  \"title\": \"Search too broad\",\r\n  \"status\": 400,\r\n  \"detail\": \"Your request has produced a list of results that is too large.\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Tax ID in use"), "\n", React.createElement(_components.p, null, "The tax ID that you supplied is already in use. Supply a unique tax ID."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#tax-id-in-use\",\r\n  \"title\": \"Tax ID already in use\",\r\n  \"status\": 409,\r\n  \"detail\": \"The tax ID that you supplied is already in use. Supply a unique tax ID\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Too many control prongs"), "\n", React.createElement(_components.p, null, "Your request included more than one owner as the control prong. You can set only one owner as the control prong."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#too-many-control-prongs\",\r\n  \"title\": \"Too many control prongs\",\r\n  \"status\": 400,\r\n  \"detail\": \"You can set only one owner as the control prong\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Unsupported media type"), "\n", React.createElement(_components.p, null, "Your payload is the wrong format. If you’re uploading a document, make sure that your attachments are in one of the following formats:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "application/pdf"), "\n", React.createElement(_components.li, null, "image/png"), "\n", React.createElement(_components.li, null, "image/bmp"), "\n", React.createElement(_components.li, null, "image/gif"), "\n", React.createElement(_components.li, null, "image/jpeg"), "\n", React.createElement(_components.li, null, "image/heic"), "\n", React.createElement(_components.li, null, "image/heif"), "\n"), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#unsupported-media-type\",\r\n  \"title\": \"Unsupported media type\",\r\n  \"status\": 415,\r\n  \"detail\": \"You submitted a payload in an unsupported format\"\r\n}\n")), "\n", React.createElement(_components.h3, null, "Volume limit has been reached"), "\n", React.createElement(_components.p, null, "This request exceeds the processing limit that we permit on your account. We return an ", React.createElement(_components.code, null, "errors"), " array that contains a ", React.createElement(_components.code, null, "detail"), " field and a ", React.createElement(_components.code, null, "message"), " field for each error. The ", React.createElement(_components.code, null, "message"), " field provides more information about the error."), "\n", React.createElement(_components.h4, null, "Example code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\r\n  \"type\": \"https://docs.payroc.com/api/errors#volume-limit-reached\",\r\n  \"title\": \"Volume limit has been reached\",\r\n  \"status\": 400,\r\n  \"detail\": \"You have exceeded the processing volume limit for your account.\",\r\n  \"errors\": [\r\n    {\r\n      \"parameter\": \"order.amount\",\r\n      \"detail\": \"Above maximum\",\r\n      \"message\": \"'amount' must not exceed USD 500\"\r\n    }\r\n  ]\r\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
